.flip-container {
  #background-color: transparent;
  #border: 1px solid #ccc;
  #border-radius: 1em;
  width: 100vw;
  height: 100vh;
  perspective: 999px;
  overflow: hidden;
}
.flip-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform .5s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}
.flip-container.back .flip-inner {
  transform: rotateY(180deg);
}
.flip-front, .flip-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  font-size: x-large;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.flip-back {
  transform: rotateY(180deg);
}
